<script>
import { layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from 'vuex';


const Menu = defineComponent({
  setup() {
    const store = useStore();

    const settings = ref({
      minScrollbarLength: 60
    })

    const user = computed(() => store.state.auth && store.state.auth.user);
    const userRoles = computed(() => JSON.parse(localStorage.getItem('roles')));
    const queue_components = computed(() => store.state.trafficRecordComponent.queue_components)

    const roleExist = (slug) => {
      if (userRoles.value && userRoles.value.length) {
          return userRoles.value.some((obj) => (obj.slug === slug));
      }

      return false;
    }

    onMounted(() => {
      store.commit('trafficRecordComponent/SET_USER_ID', user.value.id);
      store.dispatch('trafficRecordComponent/getQueueComponents');

      setTimeout(() => {
        if (document.querySelectorAll(".navbar-nav .collapse")) {
          let collapses = document.querySelectorAll(".navbar-nav .collapse");
          collapses.forEach((collapse) => {
            // Hide sibling collapses on `show.bs.collapse`
            collapse.addEventListener("show.bs.collapse", (e) => {
              e.stopPropagation();
              let closestCollapse = collapse.parentElement.closest(".collapse");
              if (closestCollapse) {
                let siblingCollapses =
                  closestCollapse.querySelectorAll(".collapse");
                siblingCollapses.forEach((siblingCollapse) => {
                  if (siblingCollapse.classList.contains("show")) {
                    siblingCollapse.classList.remove("show");
                  }
                });
              } else {
                let getSiblings = (elem) => {
                  // Setup siblings array and get the first sibling
                  let siblings = [];
                  let sibling = elem.parentNode.firstChild;
                  // Loop through each sibling and push to the array
                  while (sibling) {
                    if (sibling.nodeType === 1 && sibling !== elem) {
                      siblings.push(sibling);
                    }
                    sibling = sibling.nextSibling;
                  }
                  return siblings;
                };
                let siblings = getSiblings(collapse.parentElement);
                siblings.forEach((item) => {
                  if (item.childNodes.length > 2)
                    item.firstElementChild.setAttribute("aria-expanded", "false");
                  let ids = item.querySelectorAll("*[id]");
                  ids.forEach((item1) => {
                    item1.classList.remove("show");
                    if (item1.childNodes.length > 2) {
                      let val = item1.querySelectorAll("ul li a");

                      val.forEach((subitem) => {
                        if (subitem.hasAttribute("aria-expanded"))
                          subitem.setAttribute("aria-expanded", "false");
                      });
                    }
                  });
                });
              }
            });

            // Hide nested collapses on `hide.bs.collapse`
            collapse.addEventListener("hide.bs.collapse", (e) => {
              e.stopPropagation();
              let childCollapses = collapse.querySelectorAll(".collapse");
              childCollapses.forEach((childCollapse) => {
                let childCollapseInstance = childCollapse;
                childCollapseInstance.hide();
              });
            });
          });
        }
      }, 0);
    })

    return {
      settings,
      queue_components,
      userRoles,
      roleExist
    }
  },
  components: { SimpleBar },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
            document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                currentPosition + 300;
        }
      }, 300);
    }
  }
})

export default Menu;
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li v-show="roleExist('dashboard')" class="nav-item">
          <router-link class="nav-link menu-link" to="/">
            <i class="mdi mdi-view-dashboard"></i>
            <span data-key="t-dashboards">{{ $t("t-dashboards") }}</span>
          </router-link>
        </li>
        <!-- end Dashboard Menu -->
        <li v-if="roleExist('financial-forecast') ||  roleExist('alarm-efficiency')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarForecast"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarForecast"
          >
            <i class="mdi mdi-google-analytics"></i>
            <span data-key="t-forecast"> {{ $t("t-forecast") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarForecast">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('financial-forecast')" class="nav-item">
                <router-link
                  to="/forecast/financial-forecast"
                  class="nav-link"
                  data-key="t-financial-forecast"
                >
                  {{ $t("t-financial-forecast") }}
                </router-link>
              </li>
              <li v-if="roleExist('alarm-efficiency')" class="nav-item">
                <router-link to="/forecast/alarm-efficiency" class="nav-link" data-key="t-alarm-efficiency">
                  {{ $t("t-alarm-efficiency") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('void') || roleExist('search-and-view') || roleExist('edit-record') || roleExist('resend-to-lto') || roleExist('edit-mv-file-records') || roleExist('edit-violation')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarSearch"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarSearch"
          >
            <i class="mdi mdi-magnify"></i>
            <span data-key="t-search"> {{ $t("t-search") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarSearch">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/search/general-search"
                  class="nav-link"
                  data-key="t-general-search"
                >
                  {{ $t("t-general-search") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('input-plate-no') || roleExist('verify-traffic-record') || roleExist('validate') || roleExist('bulk-void') || roleExist('user-monitoring') || roleExist('reporting')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarTrafficRecords"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarTrafficRecords"
          >
            <i class="mdi mdi-file-check"></i>
            <span data-key="t-traffic-records">{{
              $t("t-traffic-records")
            }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTrafficRecords">
             <ul class="nav nav-sm flex-column">
                <li v-if="roleExist('input-plate-no')" class="nav-item">
                    <router-link
                      to="/traffic-records/input-plate-no"
                      class="nav-link"
                      data-key="t-input-plate-no"
                    >
                      {{ $t("t-input-plate-no") }}
                    </router-link>
                </li>
                <!-- <li v-if="roleExist('verify-traffic-record')" class="nav-item">
                    <router-link
                      to="/traffic-records/verify-traffic-record"
                      class="nav-link"
                      data-key="t-verify-traffic-record"
                    >
                      {{ $t("t-verify-traffic-record") }}
                    </router-link>
                </li> -->
                 <li v-if="roleExist('verify-traffic-record')" class="nav-item">
                  <router-link
                    to="/traffic-records/verify-validate"
                    class="nav-link"
                    data-key="t-validate"
                  >
                    {{ $t("t-validate") }}
                  </router-link>
                </li>
                <li v-if="roleExist('dispute')" class="nav-item">
                   <router-link
                     to="/traffic-records/dispute"
                     class="nav-link"
                     data-key="t-dispute"
                   >
                     {{ $t("t-dispute") }}
                   </router-link>
               </li>
              <!-- <span v-if="queue_components && queue_components.phases">
                <span v-for="phase in queue_components.phases" :key="phase.id">
                  <li v-if="roleExist(phase.slug) && phase.id !== 4" class="nav-item">
                    <router-link
                      :to="`/traffic-records/${phase.slug}`"
                      class="nav-link"
                      :data-key="`t-${phase.slug}`"
                    >
                      {{ $t(`t-${phase.slug}`) }}
                    </router-link>
                  </li>
                </span>
              </span> -->
              <li v-if="roleExist('user-monitoring')" class="nav-item">
                  <router-link
                    to="/traffic-records/user-monitoring"
                    class="nav-link"
                    data-key="t-user-monitoring"
                  >
                    {{ $t("t-user-monitoring") }}
                  </router-link>
                </li>
                 <li v-if="roleExist('bulk-void')" class="nav-item">
                  <router-link
                    to="/traffic-records/bulk-void"
                    class="nav-link"
                    data-key="t-bulk-void"
                  >
                    {{ $t("t-bulk-void") }}
                  </router-link>
                </li>
                 <li v-if="roleExist('reporting')" class="nav-item">
                  <router-link
                    to="/traffic-records/reporting"
                    class="nav-link"
                    data-key="t-reporting"
                  >
                    {{ $t("t-reporting") }}
                  </router-link>
                </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('approval') || roleExist('rejected-record')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarLGU"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarLGU"
          >
            <i class="mdi mdi-home-assistant"></i>
            <span data-key="t-lgu"> {{ $t("t-lgu") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarLGU">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('approval')" class="nav-item">
                <router-link
                  to="/traffic-records/lgu-approval"
                  class="nav-link"
                  data-key="t-approval"
                >
                  {{ $t("t-approval") }}
                </router-link>
              </li>
              <li v-if="roleExist('rejected-record')" class="nav-item">
                 <router-link
                   to="/traffic-records/rejected-record"
                   class="nav-link"
                   data-key="t-rejected-record"
                 >
                   {{ $t("t-rejected-record") }}
                 </router-link>
             </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('edit-manifest') || roleExist('print-ovr') || roleExist('edit-ovr') || roleExist('batch-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarOVR"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarOVR"
          >
            <i class="mdi mdi-receipt"></i>
            <span data-key="t-ovr"> {{ $t("t-ovr") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarOVR">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('edit-manifest')" class="nav-item">
                <router-link
                  to="/ovr/view-batch"
                  class="nav-link"
                  data-key="t-view-batch"
                >
                  {{ $t("t-view-batch") }}
                </router-link>
              </li>
              <li v-if="roleExist('print-ovr')" class="nav-item">
                <router-link
                  to="/ovr/print-ovr"
                  class="nav-link"
                  data-key="t-print-ovr"
                >
                  {{ $t("t-print-ovr") }}
                </router-link>
              </li>
              <li v-if="roleExist('edit-ovr')" class="nav-item">
                <router-link
                  to="/ovr/edit-ovr"
                  class="nav-link"
                  data-key="t-edit-ovr"
                >
                  {{ $t("t-edit-ovr") }}
                </router-link>
              </li>
              <li v-if="roleExist('batch-report')" class="nav-item">
                <router-link
                  to="/ovr/batch-report"
                  class="nav-link"
                  data-key="t-batch-report"
                >
                  {{ $t("t-batch-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('search-nov')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarNOV"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarNOV"
          >
            <i class="mdi mdi-note-text"></i>
            <span data-key="t-nov"> {{ $t("t-nov") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarNOV">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/nov/search-nov"
                  class="nav-link"
                  data-key="t-search-nov"
                >
                  {{ $t("t-search-nov") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('order-of-payment') || roleExist('official-receipt') || roleExist('report') ||
          roleExist('recently-paid') || roleExist('edit-or') || roleExist('ucpb-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarPayment"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarPayment"
          >
            <i class="mdi mdi-cash-100"></i>
            <span data-key="t-payment"> {{ $t("t-payment") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarPayment">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('order-of-payment')" class="nav-item">
                <router-link
                  to="/payment/order"
                  class="nav-link"
                  data-key="t-order-of-payment"
                >
                  {{ $t("t-order-of-payment") }}
                </router-link>
              </li>
               <li v-if="roleExist('official-receipt')" class="nav-item">
                <router-link
                  to="/payment/official-receipt"
                  class="nav-link"
                  data-key="t-official-receipt"
                >
                  {{ $t("t-official-receipt") }}
                </router-link>
              </li>
               <li v-if="roleExist('report')" class="nav-item">
                <router-link
                  to="/payment/report"
                  class="nav-link"
                  data-key="t-report"
                >
                  {{ $t("t-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('recently-paid')" class="nav-item">
                <router-link
                  to="/payment/recently-paid"
                  class="nav-link"
                  data-key="t-recently-paid"
                >
                  {{ $t("t-recently-paid") }}
                </router-link>
              </li>
               <li v-if="roleExist('edit-or')" class="nav-item">
                <router-link
                  to="/payment/edit-or"
                  class="nav-link"
                  data-key="t-edit-or"
                >
                  {{ $t("t-edit-or") }}
                </router-link>
              </li>
               <li v-if="roleExist('ucpb-report')" class="nav-item">
                <router-link
                  to="/payment/ucpb-report"
                  class="nav-link"
                  data-key="t-ucpb-report"
                >
                  {{ $t("t-ucpb-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('print-novs-return-card') || roleExist('add-tracking') || roleExist('print-manifest') ||
          roleExist('delivery-report') || roleExist('delivery-entry-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarDelivery"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDelivery"
          >
            <i class="mdi mdi-truck-delivery"></i>
            <span data-key="t-delivery"> {{ $t("t-delivery") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDelivery">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('print-novs-return-card')" class="nav-item">
                <router-link
                  to="/delivery/print-nov"
                  class="nav-link"
                  data-key="t-print-nov"
                >
                  {{ $t("t-print-nov") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-tracking')" class="nav-item">
                <router-link
                  to="/delivery/add-tracking"
                  class="nav-link"
                  data-key="t-add-tracking"
                >
                  {{ $t("t-add-tracking") }}
                </router-link>
              </li>
               <li v-if="roleExist('add-tracking')" class="nav-item">
                <router-link
                  to="/delivery/couriers/list"
                  class="nav-link"
                  data-key="t-couriers"
                >
                  {{ $t("t-couriers") }}
                </router-link>
              </li>
               <li v-if="roleExist('print-manifest')" class="nav-item">
                <router-link
                  to="/delivery/print-manifest"
                  class="nav-link"
                  data-key="t-print-manifest"
                >
                  {{ $t("t-print-manifest") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivery-report')" class="nav-item">
                <router-link
                  to="/delivery/report"
                  class="nav-link"
                  data-key="t-delivery-report"
                >
                  {{ $t("t-delivery-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivery-entry-report')" class="nav-item">
                <router-link
                  to="/delivery/entry-report"
                  class="nav-link"
                  data-key="t-delivery-entry-report"
                >
                  {{ $t("t-delivery-entry-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('batch-received-by-philpost') || roleExist('delivered-returned-rts') || roleExist('update-delivery-info')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarTracking"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarTracking"
          >
            <i class="mdi mdi-monitor-multiple"></i>
            <span data-key="t-tracking"> {{ $t("t-tracking") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTracking">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('batch-received-by-philpost')" class="nav-item">
                <router-link
                  to="/tracking/batch-received-couriers"
                  class="nav-link"
                  data-key="t-batch-received-by-philpost"
                >
                  {{ $t("t-batch-received-by-philpost") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivered-returned-rts')" class="nav-item">
                <router-link
                  to="/tracking/delivered"
                  class="nav-link"
                  data-key="t-delivered"
                >
                  {{ $t("t-delivered") }}
                </router-link>
              </li>
               <li v-if="roleExist('update-delivery-info')" class="nav-item">
                <router-link
                  to="/tracking/delivery-info"
                  class="nav-link"
                  data-key="t-update-delivery-info"
                >
                  {{ $t("t-update-delivery-info") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('manual-send-request') || roleExist('manual-upload-mv') || roleExist('api-logs') || roleExist('apprehension-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarApprehension"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarApprehension"
          >
            <i class="mdi mdi-yin-yang"></i>
            <span data-key="t-apprehension"> {{ $t("t-apprehension") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApprehension">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('manual-send-request')" class="nav-item">
                <router-link
                  to="/apprehension/mv-records"
                  class="nav-link"
                  data-key="t-mv-records"
                >
                  {{ $t("t-mv-records") }}
                </router-link>
              </li>
              <li v-if="roleExist('manual-upload-mv')" class="nav-item">
                <router-link
                  to="/apprehension/upload-lift"
                  class="nav-link"
                  data-key="t-upload-lift"
                >
                  {{ $t("t-upload-lift") }}
                </router-link>
              </li>
               <li v-if="roleExist('api-logs')" class="nav-item">
                <router-link
                  to="/apprehension/api-logs"
                  class="nav-link"
                  data-key="t-api-logs"
                >
                  {{ $t("t-api-logs") }}
                </router-link>
              </li>
               <li v-if="roleExist('apprehension-report')" class="nav-item">
                <router-link
                  to="/apprehension/report"
                  class="nav-link"
                  data-key="t-apprehension-report"
                >
                  {{ $t("t-apprehension-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('list-user') || roleExist('add-user') || roleExist('view-profile') ||
          roleExist('edit-user') || roleExist('add-role') || roleExist('edit-role') || roleExist('lgu-access') ||
          roleExist('audit-trail') || roleExist('user-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarUserManagement"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarUserManagement"
          >
            <i class="mdi mdi-account-key"></i>
            <span data-key="t-user-management"> {{ $t("t-user-management") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarUserManagement">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('list-user') || roleExist('add-user')" class="nav-item">
                <router-link
                  to="/user-management/users/list"
                  class="nav-link"
                  data-key="t-user-list"
                >
                  {{ $t("t-user-list") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-user')" class="nav-item">
               <router-link
                  to="/user-management/generate-temporary-password"
                  class="nav-link"
                  data-key="t-user-generate-temporary-password"
                >
                  {{ $t("t-user-generate-temporary-password") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-role') || roleExist('edit-role')" class="nav-item">
                <router-link
                  to="/user-management/roles/list"
                  class="nav-link"
                  data-key="t-roles-and-permissions"
                >
                  {{ $t("t-roles-and-permissions") }}
                </router-link>
              </li>
               <li v-if="roleExist('lgu-access')" class="nav-item">
                <router-link
                  to="/user-management/lgu-access"
                  class="nav-link"
                  data-key="t-lgu-access"
                >
                  {{ $t("t-lgu-access") }}
                </router-link>
              </li>
               <li v-if="roleExist('audit-trail')" class="nav-item">
                <router-link
                  to="/user-management/audit-trail"
                  class="nav-link"
                  data-key="t-audit-trail"
                >
                  {{ $t("t-audit-trail") }}
                </router-link>
              </li>
              <li v-if="roleExist('user-report')" class="nav-item">
                <router-link
                  to="/user-management/user-report"
                  class="nav-link"
                  data-key="t-user-report"
                >
                  {{ $t("t-user-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('lto-report') || roleExist('payment-report') || roleExist('violation-report') || roleExist('lgu-approval-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarAccounting"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarAccounting"
          >
            <i class="mdi mdi-cash-multiple"></i>
            <span data-key="t-accounting"> {{ $t("t-accounting") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAccounting">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('lto-report')" class="nav-item">
                <router-link
                  to="/accounting/lto-report"
                  class="nav-link"
                  data-key="t-lto-report"
                >
                  {{ $t("t-lto-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('payment-report')" class="nav-item">
                <router-link
                  to="/accounting/payment-report"
                  class="nav-link"
                  data-key="t-payment-report"
                >
                  {{ $t("t-payment-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('violation-report')" class="nav-item">
                <router-link
                  to="/accounting/violation-report"
                  class="nav-link"
                  data-key="t-violation-report"
                >
                  {{ $t("t-violation-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('lgu-approval-report')" class="nav-item">
                <router-link
                  to="/accounting/lgu-approval-report"
                  class="nav-link"
                  data-key="t-lgu-approval-report"
                >
                  {{ $t("t-lgu-approval-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('camera-dashboard') || roleExist('camera-type') || roleExist('camera-process') ||
            roleExist('camera-sort') || roleExist('camera-location') || roleExist('camera-filter')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarCamera"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarCamera"
          >
            <i class="mdi mdi-camera-iris"></i>
            <span data-key="t-camera"> {{ $t("t-camera") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarCamera">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('camera-dashboard')" class="nav-item">
                <router-link
                  to="/camera/dashboard/list"
                  class="nav-link"
                  data-key="t-camera-dashboard"
                >
                  {{ $t("t-camera-dashboard") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-type')" class="nav-item">
                <router-link
                  to="/camera/type/list"
                  class="nav-link"
                  data-key="t-camera-type"
                >
                  {{ $t("t-camera-type") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-process')" class="nav-item">
                <router-link
                  to="/camera/process"
                  class="nav-link"
                  data-key="t-camera-process"
                >
                  {{ $t("t-camera-process") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-sort')" class="nav-item">
                <router-link
                  to="/camera/sort/list"
                  class="nav-link"
                  data-key="t-camera-sort"
                >
                  {{ $t("t-camera-sort") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-location')" class="nav-item">
                <router-link
                  to="/camera/locations/list"
                  class="nav-link"
                  data-key="t-camera-location"
                >
                  {{ $t("t-camera-location") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-filter')" class="nav-item">
                <router-link
                  to="/camera/filter"
                  class="nav-link"
                  data-key="t-camera-filter"
                >
                  {{ $t("t-camera-filter") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('violation-reports') || roleExist('violation-reject-report') || roleExist('alarmed-delivered-report') ||
              roleExist('productivity-report') || roleExist('lgu-violation-report') || roleExist('double-ovr-report') || roleExist('nov-city-completeness')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarReports"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarReports"
          >
            <i class="mdi mdi-format-list-bulleted"></i>
            <span data-key="t-reports"> {{ $t("t-reports") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarReports">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('violation-reports')" class="nav-item">
                <router-link
                  to="/reports/violation"
                  class="nav-link"
                  data-key="t-violation-report"
                >
                  {{ $t("t-violation-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('alarmed-delivered-report')" class="nav-item">
                <router-link
                  to="/reports/alarmed-delivered"
                  class="nav-link"
                  data-key="t-alarmed-delivered-report"
                >
                  {{ $t("t-alarmed-delivered-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('productivity-report')" class="nav-item">
                <router-link
                  to="/reports/productivity"
                  class="nav-link"
                  data-key="t-productivity-report"
                >
                  {{ $t("t-productivity-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('lgu-violation-report')"  class="nav-item">
                <router-link
                  to="/reports/lgu-violation"
                  class="nav-link"
                  data-key="t-lgu-violation-report"
                >
                  {{ $t("t-lgu-violation-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('double-ovr-report')" class="nav-item">
                <router-link
                  to="/reports/double-ovr"
                  class="nav-link"
                  data-key="t-double-ovr-report"
                >
                  {{ $t("t-double-ovr-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('nov-city-completeness')" class="nav-item">
                <router-link
                  to="/reports/nov-city-completeness"
                  class="nav-link"
                  data-key="t-nov-city-completeness"
                >
                  {{ $t("t-nov-city-completeness") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('violation-type') || roleExist('mv-type') || roleExist('mv-registration-type') ||
            roleExist('lgu') || roleExist('nov-template') || roleExist('return-card') || roleExist('reject-reasons') ||
            roleExist('public-access') || roleExist('camera') || roleExist('whitelist')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarSettings"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarSettings"
          >
            <i class="mdi mdi-lumx"></i>
            <span data-key="t-settings"> {{ $t("t-settings") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarSettings">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('violation-type')" class="nav-item">
                <router-link
                  to="/settings/violation-types/list"
                  class="nav-link"
                  data-key="t-violation-type"
                >
                  {{ $t("t-violation-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('mv-type')" class="nav-item">
                <router-link
                  to="/settings/mv-type/list"
                  class="nav-link"
                  data-key="t-mv-type"
                >
                  {{ $t("t-mv-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('mv-registration-type')"  class="nav-item">
                <router-link
                  to="/settings/mv-registration-type/list"
                  class="nav-link"
                  data-key="t-mv-registration-type"
                >
                  {{ $t("t-mv-registration-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('lgu')" class="nav-item">
                <router-link
                  to="/settings/lgu/list"
                  class="nav-link"
                  data-key="t-lgu"
                >
                  {{ $t("t-lgu") }}
                </router-link>
              </li>
              <li v-if="roleExist('nov-template')" class="nav-item">
                <router-link
                  to="/settings/nov-templates"
                  class="nav-link"
                  data-key="t-nov-template"
                >
                  {{ $t("t-nov-template") }}
                </router-link>
              </li>
              <li v-if="roleExist('return-card')" class="nav-item">
                <router-link
                  to="/settings/return-card"
                  class="nav-link"
                  data-key="t-return-card"
                >
                  {{ $t("t-return-card") }}
                </router-link>
              </li>
              <li v-if="roleExist('reject-reasons')" class="nav-item">
                <router-link
                  to="/settings/reject-reasons/list"
                  class="nav-link"
                  data-key="t-reject-reasons"
                >
                  {{ $t("t-reject-reasons") }}
                </router-link>
              </li>
              <li v-if="roleExist('public-access')" class="nav-item">
                <router-link
                  to="/settings/public-access"
                  class="nav-link"
                  data-key="t-public-access"
                >
                  {{ $t("t-public-access") }}
                </router-link>
              </li>
              <li v-if="roleExist('camera')" class="nav-item">
                <router-link
                  to="/settings/camera"
                  class="nav-link"
                  data-key="t-camera"
                >
                  {{ $t("t-camera") }}
                </router-link>
              </li>
              <li v-if="roleExist('whitelist')" class="nav-item">
                <router-link
                  to="/settings/whitelist"
                  class="nav-link"
                  data-key="t-whitelist"
                >
                  Global {{ $t("t-whitelist") }}
                </router-link>
              </li>
              <li v-if="roleExist('whitelist')" class="nav-item">
                <router-link
                  to="/settings/whitelist-lgu"
                  class="nav-link"
                  data-key="t-whitelist-lgu"
                >
                  LGU {{ $t("t-whitelist") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li v-show="roleExist('dashboard')" class="nav-item">
          <router-link class="nav-link menu-link" to="/">
            <i class="mdi mdi-view-dashboard"></i>
            <span data-key="t-dashboards">{{ $t("t-dashboards") }}</span>
          </router-link>
        </li>
        <!-- end Dashboard Menu -->
        <li v-if="roleExist('financial-forecast') ||  roleExist('alarm-efficiency')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarForecast"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarForecast"
          >
            <i class="mdi mdi-google-analytics"></i>
            <span data-key="t-forecast"> {{ $t("t-forecast") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarForecast">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('financial-forecast')" class="nav-item">
                <router-link
                  to="/forecast/financial-forecast"
                  class="nav-link"
                  data-key="t-financial-forecast"
                >
                  {{ $t("t-financial-forecast") }}
                </router-link>
              </li>
              <li v-if="roleExist('alarm-efficiency')" class="nav-item">
                <router-link to="/forecast/alarm-efficiency" class="nav-link" data-key="t-alarm-efficiency">
                  {{ $t("t-alarm-efficiency") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('void') || roleExist('search-and-view') || roleExist('edit-record') || roleExist('resend-to-lto') || roleExist('edit-mv-file-records') || roleExist('edit-violation')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarSearch"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarSearch"
          >
            <i class="mdi mdi-magnify"></i>
            <span data-key="t-search"> {{ $t("t-search") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarSearch">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/search/general-search"
                  class="nav-link"
                  data-key="t-general-search"
                >
                  {{ $t("t-general-search") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('input-plate-no') || roleExist('verify-traffic-record') || roleExist('validate') || roleExist('bulk-void') || roleExist('user-monitoring') || roleExist('reporting')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarTrafficRecords"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarTrafficRecords"
          >
            <i class="mdi mdi-file-check"></i>
            <span data-key="t-traffic-records">{{
              $t("t-traffic-records")
            }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTrafficRecords">
             <ul class="nav nav-sm flex-column">
                <li v-if="roleExist('input-plate-no')" class="nav-item">
                    <router-link
                      to="/traffic-records/input-plate-no"
                      class="nav-link"
                      data-key="t-input-plate-no"
                    >
                      {{ $t("t-input-plate-no") }}
                    </router-link>
                </li>
                <!-- <li v-if="roleExist('verify-traffic-record')" class="nav-item">
                    <router-link
                      to="/traffic-records/verify-traffic-record"
                      class="nav-link"
                      data-key="t-verify-traffic-record"
                    >
                      {{ $t("t-verify-traffic-record") }}
                    </router-link>
                </li> -->
                 <li v-if="roleExist('verify-traffic-record')" class="nav-item">
                  <router-link
                    to="/traffic-records/verify-validate"
                    class="nav-link"
                    data-key="t-verify-traffic-record"
                  >
                    {{ $t("t-validate") }}
                  </router-link>
                </li>
                <li v-if="roleExist('dispute')" class="nav-item">
                   <router-link
                     to="/traffic-records/dispute"
                     class="nav-link"
                     data-key="t-dispute"
                   >
                     {{ $t("t-dispute") }}
                   </router-link>
               </li>
              <!-- <span v-if="queue_components && queue_components.phases">
                <span v-for="phase in queue_components.phases" :key="phase.id">
                  <li v-if="roleExist(phase.slug) && phase.id !== 4" class="nav-item">
                    <router-link
                      :to="`/traffic-records/${phase.slug}`"
                      class="nav-link"
                      :data-key="`t-${phase.slug}`"
                    >
                      {{ $t(`t-${phase.slug}`) }}
                    </router-link>
                  </li>
                </span>
              </span> -->
                <li v-if="roleExist('user-monitoring')" class="nav-item">
                    <router-link
                      to="/traffic-records/user-monitoring"
                      class="nav-link"
                      data-key="t-user-monitoring"
                    >
                      {{ $t("t-user-monitoring") }}
                    </router-link>
                </li>
                <li v-if="roleExist('bulk-void')" class="nav-item">
                  <router-link
                    to="/traffic-records/bulk-void"
                    class="nav-link"
                    data-key="t-bulk-void"
                  >
                    {{ $t("t-bulk-void") }}
                  </router-link>
                </li>
                 <li v-if="roleExist('reporting')" class="nav-item">
                  <router-link
                    to="/traffic-records/reporting"
                    class="nav-link"
                    data-key="t-reporting"
                  >
                    {{ $t("t-reporting") }}
                  </router-link>
                </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('approval') || roleExist('rejected-record')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarLGU"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarLGU"
          >
            <i class="mdi mdi-home-assistant"></i>
            <span data-key="t-lgu"> {{ $t("t-lgu") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarLGU">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('approval')" class="nav-item">
                <router-link
                  to="/traffic-records/lgu-approval"
                  class="nav-link"
                  data-key="t-approval"
                >
                  {{ $t("t-approval") }}
                </router-link>
              </li>
              <li v-if="roleExist('rejected-record')" class="nav-item">
                 <router-link
                   to="/traffic-records/rejected-record"
                   class="nav-link"
                   data-key="t-rejected-record"
                 >
                   {{ $t("t-rejected-record") }}
                 </router-link>
             </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('edit-manifest') || roleExist('print-ovr') || roleExist('edit-ovr') || roleExist('batch-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarOVR"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarOVR"
          >
            <i class="mdi mdi-receipt"></i>
            <span data-key="t-ovr"> {{ $t("t-ovr") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarOVR">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('edit-manifest')" class="nav-item">
                <router-link
                  to="/ovr/view-batch"
                  class="nav-link"
                  data-key="t-view-batch"
                >
                  {{ $t("t-view-batch") }}
                </router-link>
              </li>
              <li v-if="roleExist('print-ovr')" class="nav-item">
                <router-link
                  to="/ovr/print-ovr"
                  class="nav-link"
                  data-key="t-print-ovr"
                >
                  {{ $t("t-print-ovr") }}
                </router-link>
              </li>
              <li v-if="roleExist('edit-ovr')" class="nav-item">
                <router-link
                  to="/ovr/edit-ovr"
                  class="nav-link"
                  data-key="t-edit-ovr"
                >
                  {{ $t("t-edit-ovr") }}
                </router-link>
              </li>
              <li v-if="roleExist('batch-report')" class="nav-item">
                <router-link
                  to="/ovr/batch-report"
                  class="nav-link"
                  data-key="t-batch-report"
                >
                  {{ $t("t-batch-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('search-nov')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarNOV"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarNOV"
          >
            <i class="mdi mdi-note-text"></i>
            <span data-key="t-nov"> {{ $t("t-nov") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarNOV">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/nov/search-nov"
                  class="nav-link"
                  data-key="t-search-nov"
                >
                  {{ $t("t-search-nov") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('order-of-payment') || roleExist('official-receipt') || roleExist('report') ||
          roleExist('recently-paid') || roleExist('edit-or') || roleExist('ucpb-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarPayment"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarPayment"
          >
            <i class="mdi mdi-cash-100"></i>
            <span data-key="t-payment"> {{ $t("t-payment") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarPayment">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('order-of-payment')" class="nav-item">
                <router-link
                  to="/payment/order"
                  class="nav-link"
                  data-key="t-order-of-payment"
                >
                  {{ $t("t-order-of-payment") }}
                </router-link>
              </li>
               <li v-if="roleExist('official-receipt')" class="nav-item">
                <router-link
                  to="/payment/official-receipt"
                  class="nav-link"
                  data-key="t-official-receipt"
                >
                  {{ $t("t-official-receipt") }}
                </router-link>
              </li>
               <li v-if="roleExist('report')" class="nav-item">
                <router-link
                  to="/payment/report"
                  class="nav-link"
                  data-key="t-report"
                >
                  {{ $t("t-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('recently-paid')" class="nav-item">
                <router-link
                  to="/payment/recently-paid"
                  class="nav-link"
                  data-key="t-recently-paid"
                >
                  {{ $t("t-recently-paid") }}
                </router-link>
              </li>
               <li v-if="roleExist('edit-or')" class="nav-item">
                <router-link
                  to="/payment/edit-or"
                  class="nav-link"
                  data-key="t-edit-or"
                >
                  {{ $t("t-edit-or") }}
                </router-link>
              </li>
               <li v-if="roleExist('ucpb-report')" class="nav-item">
                <router-link
                  to="/payment/ucpb-report"
                  class="nav-link"
                  data-key="t-ucpb-report"
                >
                  {{ $t("t-ucpb-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('print-novs-return-card') || roleExist('add-tracking') || roleExist('print-manifest') ||
          roleExist('delivery-report') || roleExist('delivery-entry-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarDelivery"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDelivery"
          >
            <i class="mdi mdi-truck-delivery"></i>
            <span data-key="t-delivery"> {{ $t("t-delivery") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDelivery">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('print-novs-return-card')" class="nav-item">
                <router-link
                  to="/delivery/print-nov"
                  class="nav-link"
                  data-key="t-print-nov"
                >
                  {{ $t("t-print-nov") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-tracking')" class="nav-item">
                <router-link
                  to="/delivery/add-tracking"
                  class="nav-link"
                  data-key="t-add-tracking"
                >
                  {{ $t("t-add-tracking") }}
                </router-link>
              </li>
               <li v-if="roleExist('add-tracking')" class="nav-item">
                <router-link
                  to="/delivery/couriers/list"
                  class="nav-link"
                  data-key="t-couriers"
                >
                  {{ $t("t-couriers") }}
                </router-link>
              </li>
               <li v-if="roleExist('print-manifest')" class="nav-item">
                <router-link
                  to="/delivery/print-manifest"
                  class="nav-link"
                  data-key="t-print-manifest"
                >
                  {{ $t("t-print-manifest") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivery-report')" class="nav-item">
                <router-link
                  to="/delivery/report"
                  class="nav-link"
                  data-key="t-delivery-report"
                >
                  {{ $t("t-delivery-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivery-entry-report')" class="nav-item">
                <router-link
                  to="/delivery/entry-report"
                  class="nav-link"
                  data-key="t-delivery-entry-report"
                >
                  {{ $t("t-delivery-entry-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('batch-received-by-philpost') || roleExist('delivered-returned-rts') || roleExist('update-delivery-info')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarTracking"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarTracking"
          >
            <i class="mdi mdi-monitor-multiple"></i>
            <span data-key="t-tracking"> {{ $t("t-tracking") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTracking">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('batch-received-by-philpost')" class="nav-item">
                <router-link
                  to="/tracking/batch-received-couriers"
                  class="nav-link"
                  data-key="t-batch-received-by-philpost"
                >
                  {{ $t("t-batch-received-by-philpost") }}
                </router-link>
              </li>
               <li v-if="roleExist('delivered-returned-rts')" class="nav-item">
                <router-link
                  to="/tracking/delivered"
                  class="nav-link"
                  data-key="t-delivered"
                >
                  {{ $t("t-delivered") }}
                </router-link>
              </li>
               <li v-if="roleExist('update-delivery-info')" class="nav-item">
                <router-link
                  to="/tracking/delivery-info"
                  class="nav-link"
                  data-key="t-update-delivery-info"
                >
                  {{ $t("t-update-delivery-info") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="roleExist('manual-send-request') || roleExist('manual-upload-mv') || roleExist('api-logs') || roleExist('apprehension-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarApprehension"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarApprehension"
          >
            <i class="mdi mdi-yin-yang"></i>
            <span data-key="t-apprehension"> {{ $t("t-apprehension") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApprehension">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('manual-send-request')" class="nav-item">
                <router-link
                  to="/apprehension/mv-records"
                  class="nav-link"
                  data-key="t-mv-records"
                >
                  {{ $t("t-mv-records") }}
                </router-link>
              </li>
              <li v-if="roleExist('manual-upload-mv')" class="nav-item">
                <router-link
                  to="/apprehension/upload-lift"
                  class="nav-link"
                  data-key="t-upload-lift"
                >
                  {{ $t("t-upload-lift") }}
                </router-link>
              </li>
               <li v-if="roleExist('api-logs')" class="nav-item">
                <router-link
                  to="/apprehension/api-logs"
                  class="nav-link"
                  data-key="t-api-logs"
                >
                  {{ $t("t-api-logs") }}
                </router-link>
              </li>
               <li v-if="roleExist('apprehension-report')" class="nav-item">
                <router-link
                  to="/apprehension/report"
                  class="nav-link"
                  data-key="t-apprehension-report"
                >
                  {{ $t("t-apprehension-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('list-user') || roleExist('add-user') || roleExist('view-profile') ||
          roleExist('edit-user') || roleExist('add-role') || roleExist('edit-role') || roleExist('lgu-access') ||
          roleExist('audit-trail') || roleExist('user-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarUserManagement"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarUserManagement"
          >
            <i class="mdi mdi-account-key"></i>
            <span data-key="t-user-management"> {{ $t("t-user-management") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarUserManagement">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('list-user') || roleExist('add-user')" class="nav-item">
                <router-link
                  to="/user-management/users/list"
                  class="nav-link"
                  data-key="t-user-list"
                >
                  {{ $t("t-user-list") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-user')" class="nav-item">
               <router-link
                  to="/user-management/generate-temporary-password"
                  class="nav-link"
                  data-key="t-user-generate-temporary-password"
                >
                  {{ $t("t-user-generate-temporary-password") }}
                </router-link>
              </li>
              <li v-if="roleExist('add-role') || roleExist('edit-role')" class="nav-item">
                <router-link
                  to="/user-management/roles/list"
                  class="nav-link"
                  data-key="t-roles-and-permissions"
                >
                  {{ $t("t-roles-and-permissions") }}
                </router-link>
              </li>
               <li v-if="roleExist('lgu-access')" class="nav-item">
                <router-link
                  to="/user-management/lgu-access"
                  class="nav-link"
                  data-key="t-lgu-access"
                >
                  {{ $t("t-lgu-access") }}
                </router-link>
              </li>
               <li v-if="roleExist('audit-trail')" class="nav-item">
                <router-link
                  to="/user-management/audit-trail"
                  class="nav-link"
                  data-key="t-audit-trail"
                >
                  {{ $t("t-audit-trail") }}
                </router-link>
              </li>
              <li v-if="roleExist('user-report')" class="nav-item">
                <router-link
                  to="/user-management/user-report"
                  class="nav-link"
                  data-key="t-user-report"
                >
                  {{ $t("t-user-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('lto-report') || roleExist('payment-report') || roleExist('violation-report') || roleExist('lgu-approval-report')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarAccounting"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarAccounting"
          >
            <i class="mdi mdi-cash-multiple"></i>
            <span data-key="t-accounting"> {{ $t("t-accounting") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAccounting">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('lto-report')" class="nav-item">
                <router-link
                  to="/accounting/lto-report"
                  class="nav-link"
                  data-key="t-lto-report"
                >
                  {{ $t("t-lto-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('payment-report')" class="nav-item">
                <router-link
                  to="/accounting/payment-report"
                  class="nav-link"
                  data-key="t-payment-report"
                >
                  {{ $t("t-payment-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('violation-report')" class="nav-item">
                <router-link
                  to="/accounting/violation-report"
                  class="nav-link"
                  data-key="t-violation-report"
                >
                  {{ $t("t-violation-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('lgu-approval-report')" class="nav-item">
                <router-link
                  to="/accounting/lgu-approval-report"
                  class="nav-link"
                  data-key="t-lgu-approval-report"
                >
                  {{ $t("t-lgu-approval-report") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('camera-dashboard') || roleExist('camera-type') || roleExist('camera-process') ||
            roleExist('camera-sort') || roleExist('camera-location') || roleExist('camera-filter')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarCamera"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarCamera"
          >
            <i class="mdi mdi-camera-iris"></i>
            <span data-key="t-camera"> {{ $t("t-camera") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarCamera">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('camera-dashboard')" class="nav-item">
                <router-link
                  to="/camera/dashboard/list"
                  class="nav-link"
                  data-key="t-camera-dashboard"
                >
                  {{ $t("t-camera-dashboard") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-type')" class="nav-item">
                <router-link
                  to="/camera/type/list"
                  class="nav-link"
                  data-key="t-camera-type"
                >
                  {{ $t("t-camera-type") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-process')" class="nav-item">
                <router-link
                  to="/camera/process"
                  class="nav-link"
                  data-key="t-camera-process"
                >
                  {{ $t("t-camera-process") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-sort')" class="nav-item">
                <router-link
                  to="/camera/sort/list"
                  class="nav-link"
                  data-key="t-camera-sort"
                >
                  {{ $t("t-camera-sort") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-location')" class="nav-item">
                <router-link
                  to="/camera/locations/list"
                  class="nav-link"
                  data-key="t-camera-location"
                >
                  {{ $t("t-camera-location") }}
                </router-link>
              </li>
               <li v-if="roleExist('camera-filter')" class="nav-item">
                <router-link
                  to="/camera/filter"
                  class="nav-link"
                  data-key="t-camera-filter"
                >
                  {{ $t("t-camera-filter") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('violation-reports') || roleExist('violation-reject-report') || roleExist('alarmed-delivered-report') ||
              roleExist('productivity-report') || roleExist('lgu-violation-report') || roleExist('double-ovr-report') || roleExist('nov-city-completeness')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarReports"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarReports"
          >
            <i class="mdi mdi-format-list-bulleted"></i>
            <span data-key="t-reports"> {{ $t("t-reports") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarReports">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('violation-reports')" class="nav-item">
                <router-link
                  to="/reports/violation"
                  class="nav-link"
                  data-key="t-violation-report"
                >
                  {{ $t("t-violation-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('alarmed-delivered-report')" class="nav-item">
                <router-link
                  to="/reports/alarmed-delivered"
                  class="nav-link"
                  data-key="t-alarmed-delivered-report"
                >
                  {{ $t("t-alarmed-delivered-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('productivity-report')" class="nav-item">
                <router-link
                  to="/reports/productivity"
                  class="nav-link"
                  data-key="t-productivity-report"
                >
                  {{ $t("t-productivity-report") }}
                </router-link>
              </li>
               <li v-if="roleExist('productivity-report')" class="nav-item">
                <router-link
                  to="/reports/camera"
                  class="nav-link"
                  data-key="t-camera-report"
                >
                  Camera Report
                </router-link>
              </li>
               <li v-if="roleExist('productivity-report')" class="nav-item">
                <router-link
                  to="/reports/delivery"
                  class="nav-link"
                  data-key="t-delivery-report"
                >
                  Delivery Report
                </router-link>
              </li>
              <li v-if="roleExist('lgu-violation-report')"  class="nav-item">
                <router-link
                  to="/reports/lgu-violation"
                  class="nav-link"
                  data-key="t-lgu-violation-report"
                >
                  {{ $t("t-lgu-violation-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('double-ovr-report')" class="nav-item">
                <router-link
                  to="/reports/double-ovr"
                  class="nav-link"
                  data-key="t-double-ovr-report"
                >
                  {{ $t("t-double-ovr-report") }}
                </router-link>
              </li>
              <li v-if="roleExist('nov-city-completeness')" class="nav-item">
                <router-link
                  to="/reports/nov-city-completeness"
                  class="nav-link"
                  data-key="t-nov-city-completeness"
                >
                  {{ $t("t-nov-city-completeness") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

         <li v-if="roleExist('violation-type') || roleExist('mv-type') || roleExist('mv-registration-type') ||
            roleExist('lgu') || roleExist('nov-template') || roleExist('return-card') || roleExist('reject-reasons') ||
            roleExist('public-access') || roleExist('camera') ||roleExist('whitelist')" class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarSettings"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarSettings"
          >
            <i class="mdi mdi-lumx"></i>
            <span data-key="t-settings"> {{ $t("t-settings") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarSettings">
            <ul class="nav nav-sm flex-column">
              <li v-if="roleExist('violation-type')" class="nav-item">
                <router-link
                  to="/settings/violation-types/list"
                  class="nav-link"
                  data-key="t-violation-type"
                >
                  {{ $t("t-violation-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('mv-type')" class="nav-item">
                <router-link
                  to="/settings/mv-type/list"
                  class="nav-link"
                  data-key="t-mv-type"
                >
                  {{ $t("t-mv-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('mv-registration-type')"  class="nav-item">
                <router-link
                  to="/settings/mv-registration-type/list"
                  class="nav-link"
                  data-key="t-mv-registration-type"
                >
                  {{ $t("t-mv-registration-type") }}
                </router-link>
              </li>
              <li v-if="roleExist('lgu')" class="nav-item">
                <router-link
                  to="/settings/lgu/list"
                  class="nav-link"
                  data-key="t-lgu"
                >
                  {{ $t("t-lgu") }}
                </router-link>
              </li>
              <li v-if="roleExist('nov-template')" class="nav-item">
                <router-link
                  to="/settings/nov-templates"
                  class="nav-link"
                  data-key="t-nov-template"
                >
                  {{ $t("t-nov-template") }}
                </router-link>
              </li>
              <li v-if="roleExist('return-card')" class="nav-item">
                <router-link
                  to="/settings/return-card"
                  class="nav-link"
                  data-key="t-return-card"
                >
                  {{ $t("t-return-card") }}
                </router-link>
              </li>
              <li v-if="roleExist('reject-reasons')" class="nav-item">
                <router-link
                  to="/settings/reject-reasons/list"
                  class="nav-link"
                  data-key="t-reject-reasons"
                >
                  {{ $t("t-reject-reasons") }}
                </router-link>
              </li>
              <li v-if="roleExist('public-access')" class="nav-item">
                <router-link
                  to="/settings/public-access"
                  class="nav-link"
                  data-key="t-public-access"
                >
                  {{ $t("t-public-access") }}
                </router-link>
              </li>
              <li v-if="roleExist('camera')" class="nav-item">
                <router-link
                  to="/settings/camera"
                  class="nav-link"
                  data-key="t-camera"
                >
                  {{ $t("t-camera") }}
                </router-link>
              </li>
              <li v-if="roleExist('whitelist')" class="nav-item">
                <router-link
                  to="/settings/whitelist"
                  class="nav-link"
                  data-key="t-whitelist"
                >
                  Global {{ $t("t-whitelist") }}
                </router-link>
              </li>
              <li v-if="roleExist('whitelist')" class="nav-item">
                <router-link
                  to="/settings/whitelist-lgu"
                  class="nav-link"
                  data-key="t-whitelist-lgu"
                >
                  LGU {{ $t("t-whitelist") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>
