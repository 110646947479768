<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{new Date().getFullYear()}} © Traffic Citation Management System.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    This page took 1.0000 seconds to load.
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
