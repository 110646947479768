<script>
import { layoutComputed } from "@/state/helpers";

import { authService } from "../helpers/authservice/auth.service";
import moment from "moment";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";

export default {
  components: {
    Vertical,
    Horizontal,
    TwoColumns,
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },

  methods: {
    onidle() {
      const previousActiveTimestamp = localStorage.getItem("active_timestamp");

      const minutesPassed = moment().diff(previousActiveTimestamp, "minutes");

      if (minutesPassed > 15) {
        authService.logout();
      }
    },
    onactive() {
      localStorage.setItem("active_timestamp", moment().format());
    },
  },
};
</script>

<template>
  <div>
    <mt-idle-timer
      @onidle="onidle"
      @onactive="onactive"
      :duration="900"
      style="display: none; height: 0"
    />

    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>

    <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
      <slot />
    </TwoColumns>
  </div>
</template>
