<script>
import i18n from "../i18n";
import { truncate } from 'lodash';
import Swal from 'sweetalert2';

/**
 * Nav-bar Component
 */
export default {
  computed: {
    user() {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
      }
      return '';
    },
    truncate() {
      return truncate;
    }
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.svg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "sp",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/china.svg"),
          language: "ch",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/germany.svg"),
          language: "gr",
          title: "Deutsche",
        },
        {
          flag: require("@/assets/images/flags/russia.svg"),
          language: "ru",
          title: "русский",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      mode: null,
      myVar: 1
    };
  },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

    this.mode = this.user.mode

    this.getHamburgerMenu();
    this.getTheme();
   
  },
  methods: {
    getHamburgerMenu() {
      document.body.classList.remove("vertical-sidebar-enable");
      const menu = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : { size: 'lg' };
      document.documentElement.setAttribute("data-sidebar-size", menu.size );
      if (menu.size === 'lg') {
        document.querySelector(".hamburger-icon").classList.remove("open");
      } else {
        document.querySelector(".hamburger-icon").classList.add("open");
      }
    },
    toggleHamburgerMenu() {
      const menu = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : { size: 'lg' };
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        if (menu.size === 'lg') {
          document.querySelector(".hamburger-icon").classList.add("open");
        } else {
          document.querySelector(".hamburger-icon").classList.remove("open");
        }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          if (menu.size === 'lg') {
            document.documentElement.setAttribute("data-sidebar-size", "sm");
            localStorage.setItem('menu', JSON.stringify({ size: 'sm'}))
          } else {
            document.documentElement.setAttribute("data-sidebar-size", "lg");
            localStorage.setItem('menu', JSON.stringify({ size: 'lg'}))
          }
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    getTheme() {
      const theme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : { color: 'light' };
      document.documentElement.setAttribute("data-layout-mode", theme.color);
    },
    toggleDarkMode() {
      const theme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : { color: 'light' };

      if (theme.color === "dark") {
        localStorage.setItem('theme', JSON.stringify({ color: 'light'}))
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        localStorage.setItem('theme', JSON.stringify({ color: 'dark'}))
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
    getUserRoles(user) {
      const roles = user.roles;
      if (roles && roles.length > 0) {
        const rolesArray = roles.map(role => role.name);
        return rolesArray.join(', ');
      }
      return 'N/A';
    },
    async updateUserMode(user) {
        const confirm = await Swal.fire({
            icon: 'info',
            title: 'Continue?',
            text: `Do you want to change to "${user.mode}" mode?`,
            showCancelButton: true,
            confirmButtonText: 'Proceed',
        })

        if (!confirm.isConfirmed) {
          this.mode = user.mode;
          return;
        }

        let self = this;
        this.$store.dispatch('user/updateMode', {id: user.id, mode: this.mode})
        .then(function () {
            user.mode = self.mode;
            localStorage.setItem('user', JSON.stringify(user));
            Swal.fire({
                title: user.mode.toUpperCase() + ' Mode',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                position: 'top-right',
                toast: true,
                icon: 'success'
            });
        })
        .catch(() => {
          this.mode = user.mode;
            Swal.fire({
                title: 'Unable to Change Mode',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                position: 'top-right',
                toast: true,
                icon: 'danger'
            });
       })
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/qpax/logo.png" alt="" height="95" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/qpax/logo.png" alt="" height="50" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/qpax/logo.png" alt="" height="95" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/qpax/logo.png" alt="" height="50" />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            "
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                id="header-lang-img"
                src="@/assets/images/flags/us.svg"
                alt="Header Language"
                height="20"
                class="rounded"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <a
                href="javascript:void(0);"
                v-for="(entry, i) in languages"
                :key="`Lang${i}`"
                :value="entry"
                @click="setLanguage(entry.language, entry.title, entry.flag)"
                :class="{ active: lan === entry.language }"
                class="dropdown-item notify-item language py-2"
                data-lang="en"
                title="English"
              >
                <img
                  :src="entry.flag"
                  alt="user-image"
                  class="me-2 rounded"
                  height="18"
                />
                <span class="align-middle">{{ entry.title }}</span>
              </a>
            </div>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-toggle="fullscreen"
              @click="initFullScreen"
            >
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
              type="button"
              class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
              "
              @click="toggleDarkMode"
            >
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>


          <div class="dropdown ms-sm-3 header-item topbar-user" style="min-width: 184px;">
            <button
              type="button"
              class="btn"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <img
                  class="rounded-circle header-profile-user"
                  v-if="!user.image"
                  src="@/assets/images/users/user-dummy-img.jpg"
                  alt="Header Avatar"
                />
                <img
                  class="rounded-circle header-profile-user"
                  v-else
                  :src="user.image"
                  :alt="user.name"
                />
                <span class="text-start ms-xl-2">
                  <span
                    class="
                      d-none d-xl-inline-block
                      ms-1
                      fw-medium
                      user-name-text
                    "
                    >{{ user.name }}</span
                  >
                  <span
                    class="
                      d-none d-xl-block
                      ms-1
                      fs-12
                      text-muted
                      user-name-sub-text"
                    :title="getUserRoles(user)"
                    >{{ truncate(getUserRoles(user), { length: '20' }) }}</span
                  >
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">Welcome {{ user.name }}</h6>
              <router-link class="dropdown-item" to="/user/profile"
                ><i
                  class="
                    mdi mdi-account-circle
                    text-muted
                    fs-16
                    align-middle
                    me-1
                  "
                ></i>
                <span class="align-middle">User Profile</span></router-link
              >
               <router-link class="dropdown-item" to="/change-password"
                ><i
                  class="mdi mdi-key text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Change Password</span></router-link
              >
              <a class="dropdown-item">
                <span class="badge my-1 float-end">
                  <select class="form-select form-select-sm border-success" v-model="mode" @change="updateUserMode(user)" style="margin-top: -10px;">
                    <option value="test"> Test </option>
                    <option value="live"> Live </option>
                  </select>
                </span>
                <i class="mdi mdi-radiobox-blank fs-16 align-middle me-1 text-info"></i>
                <span class="align-middle">Mode</span>
              </a>
              <a class="dropdown-item" href="/logout"
                ><i
                  class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Logout</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
